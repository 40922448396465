import React from 'react';
import { Link } from 'gatsby';

import DarkLogo from './DarkLogo';

const Header = ({ siteTitle }) => (
  <nav className="uk-navbar-container uk-navbar-transparent uk-background-default" uk-navbar="true" uk-sticky="true">
    <div className="uk-navbar-left">
      <ul className="uk-navbar-nav">
        <li className="uk-active">
          <a href="/" style={{ opacity: 0.9 }}><DarkLogo /></a>
        </li>
      </ul>
    </div>

    <div className="uk-navbar-right">
      <ul className="uk-navbar-nav">
        <li>
          <Link to="/#quienes-somos" activeClassName="active">Qui&eacute;nes somos</Link>
        </li>
        <li>
          <Link to="/cursos" activeClassName="active">Cursos</Link>
        </li>
        <li>
          <Link to="/contacto" activeClassName="active">Cont&aacute;ctenos</Link>
        </li>
      </ul>
    </div>
  </nav>
);

Header.defaultProps = {
  siteTitle: '',
};

export default Header;
