import React, { Component } from 'react';
import { StaticQuery, graphql } from 'gatsby';

import Header from './header';

import '../css/theme.scss';

/*
 * Layout component for the site. Renders a menu and a footer.
 *
 * @version 1.2.0
 * @example <Layout>{children}</Layout>
 */
export default class Layout extends Component {
  state = {
    ready: false,
  };

  componentDidMount() {
    if (typeof window !== 'undefined') {
      require('uikit');
      this.setState({ ready: true });
    }
  }

  render() {
    const { children } = this.props;
    return (
      <StaticQuery
        query={graphql`
          query SiteTitleQuery {
            site {
              siteMetadata {
                title
              }
            }
          }
        `}
        render={data => (
          <>
            <Header siteTitle={data.site.siteMetadata.title} />
            <div uk-height-viewport="true">{children}</div>
            <footer className="uk-padding uk-text-center uk-text-small">
              &copy; {new Date().getFullYear()} Airline Pilot Academy. Todos los
              derechos reservados.
            </footer>
          </>
        )}
      />
    );
  }
}
